import React from 'react';

const FaqFR = () => {
  return (
    <section className="faq-area pt-60 pb-60">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="faq-section-title text-center">
              <h3 className="section-title">
                EXPÉRIENCE DÉCOUVERTE LINCOLN 2025 – FOIRE AUX QUESTIONS
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="single-faq mt-50">
              <h4>QUESTION #1 – Pour commencer</h4>
              <h6>
                Comment puis-je m’inscrire à l’Expérience découverte Lincoln?
              </h6>
              <p>Il y a deux étapes simples à suivre pour commencer :</p>
              <ul>
                <li>
                  <span>1.</span> Remplissez le formulaire d’inscription et le formulaire Surprise et Enchantement, puis envoyez-les à l’adresse
                  <a href="mailto:LDDambassador@Lincolndiscoverydrive.com">
                    LDDambassador@Lincolndiscoverydrive.com
                  </a>{' '}
                  pour inscrire votre détaillant au programme.
                </li>
                <li>
                  <span>2.</span> Lorsque vous serez inscrits, l’ambassadeur de l’Expérience découverte Lincoln remettra à votre détaillant des justificatifs d’identité uniques pour accéder au portail en ligne du programme.
                </li>
              </ul>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #2 – Exigences concernant l’Expérience découverte Lincoln</h4>
              <h6>Que dois-je faire dans le cadre du programme Expérience découverte Lincoln?</h6>
              <p>Le programme comporte cinq éléments obligatoires :</p>
              <ul>
                <li>
                  <span>1.</span> Incluez le service de collecte et de livraison (dans la mesure du possible).
                </li>
                <li>
                  <span>2.</span> Lavez le véhicule actuel du client durant l’essai routier (le cas échéant).
                </li>
                <li>
                  <span>3.</span> Déposez une lettre de bienvenue personnalisée sur le siège du véhicule.
                </li>
                <li>
                  <span>4.</span> Remettez au client un élément Surprise et Enchantement (d’une valeur minimale de 100 $).
                </li>
                <li>
                  <span>5.</span> Remplissez entièrement le formulaire d’inscription à l’Expérience découverte Lincoln dans l’outil au plus 48 heures après l’essai routier.
                </li>
              </ul>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #3 – Durée de l’Expérience découverte Lincoln</h4>
              <h6>Puis-je offrir des essais routiers de différentes durées, selon l’intérêt des clients?</h6>
              <p>
                Oui, mais le programme exige un essai routier d’au moins 24 heures. Vous pouvez prêter le véhicule pendant plus de 48 heures, mais le remboursement est plafonné à 48 heures. L’élément Surprise et Enchantement doit refléter la marque Lincoln et impressionner chaque client potentiel.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #4 – Élément Surprise et Enchantement approprié</h4>
              <h6>Puis-je continuer à offrir des cartes-cadeaux comme Surprise et Enchantement?</h6>
              <p>
                Nous vous déconseillons d’offrir uniquement des cartes-cadeaux. Optez pour un élément personnalisé et mémorable reflétant la vision de Lincoln : une boîte-cadeau locale, un partenariat avec un restaurant pour un dîner « à l’image de Lincoln », ou une escapade dans un vignoble local.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #5 – Propriétaires actuels de Lincoln et Ford</h4>
              <h6>Le programme est-il destiné aux clients actuels de Lincoln ou Ford?</h6>
              <p>
                Non, sauf pour les propriétaires de Ford Edge. Vous devez indiquer leur véhicule actuel comme un Ford Edge et inclure le NIV dans le portail. Ce programme cible principalement les clients de marques concurrentes.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #6 – Limites de soumission des programmes</h4>
              <h6>Combien d’Expériences découvertes peuvent être remboursées par mois?</h6>
              <p>
                Les détaillants peuvent soumettre 10 Expériences découvertes Lincoln de conquête par mois.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #7 – Renseignements du rapport de l’Expérience découverte Lincoln</h4>
              <h6>L’outil d’inscription permet-il de surveiller les approbations et les réclamations?</h6>
              <p>
                Oui, chaque détaillant peut accéder à un rapport listant ses événements approuvés et les réclamations payées. Les demandes non approuvées seront signalées avec des instructions complémentaires envoyées par l’ambassadeur.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #8 – Suivi de l’Expérience découverte Lincoln auprès des clients</h4>
              <h6>Est-il possible de suivre les clients après l’essai routier?</h6>
              <p>
                Oui, le Secrétariat du programme effectuera un suivi auprès des participants pour recueillir leurs impressions. Ces commentaires seront disponibles sur le portail pour consultation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqFR;
