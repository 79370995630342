import React from 'react';

const ProgramParametersEn = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row mt-30">
          <div className="col-lg-12">
            <div className="faq-section-title text-center col-lg-12 mb-20">
              <h3 className="section-title">
                2025 Lincoln Discovery Drive Program Details
              </h3>
            </div>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Program Period</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <p className="static-dark-grey">January 1 – December 31, 2025</p>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Retailer Eligibility</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> All Lincoln Retailers are eligible to
                  participate in the Lincoln Discovery Drive Program
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Retailers wishing to participate MUST
                  complete the Registration form and Surprise & Delight form
                  and submit to Program Headquarters before being eligible to
                  receive Lincoln Canada support
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Retailer monthly test drive allocation: 10/month
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Vehicle Eligibility</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> New 2024MY Corsair, Nautilus, Aviator,
                  Navigator
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> New 2025MY Corsair, Nautilus, Aviator,
                  Navigator
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">NEW FOR 2025</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Retailer monthly test drive allocation
                  has been standardized to 10/month regardless of Lincoln sales
                  volume
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Current Ford Edge customers are eligible
                  for the program, provided their VIN is included in the
                  registration portal
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Additional Key Program Highlights</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Only prospects driving competitive
                  automotive brands are eligible to participate
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Participating Retailers must include the
                  make, model, and VIN of the participant’s current vehicle
                  during registration
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Program elements will demonstrate
                  Lincoln’s vision of Warm, Human, and Personally Crafted
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Participating Retailers to access client
                  welcome letter and program creative for their websites &
                  social media channels
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Retailers are encouraged to tailor their
                  surprise and delight element to reflect their communities
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Each Discovery Drive test drive will
                  count towards 1 Service Loaner day
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Client’s current vehicle to be washed
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10 mb-50">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Participating Retailer Responsibilities
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Include the participants’ current
                  vehicle make, model, and VIN in the registration
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Place a personalized letter and Lincoln
                  Welcome Card on the driver’s seat of the test drive vehicle
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProgramParametersEn;
