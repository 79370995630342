import React from 'react';

const FaqEN = () => {
  return (
    <section className="faq-area pt-60 pb-60">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="faq-section-title text-center">
              <h3 className="section-title">
                2025 LINCOLN DISCOVERY DRIVE - FREQUENTLY ASKED QUESTIONS
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="single-faq mt-50">
              <h4>QUESTION #1 – Getting Started</h4>
              <h6>How do I get started with Lincoln Discovery Drive?</h6>
              <p>There are 2 simple steps to get started:</p>
              <ul>
                <li>
                  <span>1.</span> Fill out the Registration Form/Surprise & Delight Options Form and send to{' '}
                  <a href="mailto:LDDambassador@Lincolndiscoverydrive.com">
                    LDDambassador@Lincolndiscoverydrive.com
                  </a>{' '}
                  to register your store for the program.
                </li>
                <li>
                  <span>2.</span> Once registered, the Lincoln Discovery Drive Ambassador will provide your store with a single
                  distinct login and password to access the Lincoln Discovery Drive online portal so you can register
                  and track your program activity.
                </li>
              </ul>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #2 – LDD Requirements</h4>
              <h6>What am I required to do for the Lincoln Discovery Drive Program?</h6>
              <p>There are 5 required elements to the program:</p>
              <ul>
                <li>
                  <span>1.</span> Include Pickup & Delivery (where possible).
                </li>
                <li>
                  <span>2.</span> Wash the Client’s current vehicle during their test drive (where applicable).
                </li>
                <li>
                  <span>3.</span> Provide a personalized Client Welcome Letter on the seat of the test drive vehicle.
                </li>
                <li>
                  <span>4.</span> Provide the Client with a Surprise & Delight Experience (minimum of $100 value).
                </li>
                <li>
                  <span>5.</span> Fully complete the required LDD Registration form in the tool no more than 48 hours following the test drive.
                </li>
              </ul>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #3 – LDD Length of Time</h4>
              <h6>Can I offer different test drive time frames based on client interest?</h6>
              <p>
                Yes, but the LDD program requires a minimum 24-hour test drive period. You are also welcome to provide the vehicle for
                more than 48 hours, but reimbursement is capped at 48 hours. The Surprise & Delight portion of the experience must
                still reflect the Lincoln brand and wow every prospect who participates in the program.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #4 – Appropriate Surprise & Delight</h4>
              <h6>In the past I have given participants a gift card for dinner. Can I continue to do that?</h6>
              <p>
                We strongly discourage you from providing only a gift card. We want the surprise and delight to reflect our vision of Warm
                Human and Personally Crafted. Plan to provide a Surprise & Delight that is more personalized and memorable. Some
                ideas to get you started: a gift box or basket of goodies from a local retailer, establish a partnership with a local restaurant
                for a special “Lincoln-branded” dinner for two, a weekend B&B package at a local winery or brewery. Remember a
                “Lincoln-worthy” surprise and delight is required to be eligible for Lincoln LDD funding support.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #5 – Existing Lincoln and Ford Owners</h4>
              <h6>Program information talks about new prospects, can we offer the experience to existing Lincoln and Ford owners?</h6>
              <p>
                No. Lincoln Discovery Drive was developed as a powerful conquest sales tool to help you see increased Brand perception
                and to close more conquest sales in your markets. You are more than welcome to provide the same experience to your
                loyal clients but you won’t be able to claim reimbursement.
              </p>
              <p>
                Exception – current Ford Edge owners may be offered a LDD, that will be reimbursed, to introduce them to Lincoln. You must
                indicate that the client’s current (competitive) brand is a Ford Edge and include the (competitive) vehicle VIN of the Edge.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #6 – Retailer Limits</h4>
              <h6>Is there a limit to the number of LDDs a Retailer can be reimbursed for?</h6>
              <p>
                Yes – Retailers may submit a total of 10 conquest LDDs per month.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #7 – LDD Report Information</h4>
              <h6>Will the registration tool allow us to monitor claim approvals and volumes?</h6>
              <p>
                Yes, each Retailer will have access to a list of their events and total amount of claims paid. You will also see a check mark
                under the “Approved” column in your store’s Report in the LDD Tool. If there is not a check mark, the LDD is not approved
                and may be pending for further information. The LDD Ambassador will email your store’s registered Lincoln Contact with
                any questions or issues.
              </p>
            </div>
            <div className="single-faq mt-50">
              <h4>QUESTION #8 – LDD Client Follow Up</h4>
              <h6>Can we follow up with the test drive participant after their experience?</h6>
              <p>
                You are more than welcome to follow up with your client, however Program Headquarters will also be following up with
                program participants to gauge their opinion of the brand and overall experience. These comments will be included in the
                LDD portal for your review and program tracking purposes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqEN;
