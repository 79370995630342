import React, { Component } from 'react'
import HeaderFr from '../containers/headerFr'
import FooterFr from '../components/footerFr'
//import SupportFR from "../components/supportFR";
import { Navigate, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
// import ClientReportListFr from "../components/clientReportListFr";
import Select from 'react-select'
import { CSVLink } from 'react-csv'
import ReactTable, { ReactTableDefaults } from 'react-table'
import 'react-table/react-table.css'
import styled from 'styled-components'
import './customStyles.css'
import { BallTriangle } from 'react-loader-spinner'
import _ from 'lodash'

const DownloadDiv = styled.div`
  width: 100;
  margin-top: 2em;
`

const TableDiv = styled.div`
  width: 100;
`
// const NumberOfRecords = styled.div`
//   font-weight: bold;
//   display: inline-flex;
//   margin-left: 2em;
//   `

const date = new Date()
class DealerReportFr extends Component {
  constructor(props) {
    super(props)
    this.state = {
      csvMonth: (date.getMonth() + 1).toString(),
      csvYear: date.getFullYear().toString(),
      sortedTableData: [],
      selectedYear: { label: 'Tout', value: 'All' },
    }
    this.sortedTableDataRef = React.createRef()
  }

  render() {
    let { clients, profile } = this.props
    let clientOptionArray = []
    clientOptionArray.push({ label: 'Tout', value: 'All' })
    let clientMap = new Map()
    if (clients) {
      // let numberOfObjects;
      let validatedDealers = []
      let numberOfvalidatedDealers
      let allContact = [0]
      let rt
      let contact
      let totalReg = []

      clients.map((client, index) => {
        client = _.cloneDeep(client)
        if (client.dealerCode === profile.dealerCode) {
          if (client.clientFirstName && client.clientLastName) {
            client.fullName =
              client.clientFirstName + ' ' + client.clientLastName
          } else if (client.clientFirstName) {
            client.fullName = client.clientFirstName
          } else {
            client.fullName = client.clientLastName
          }
          client.validationLink = <span>{client.id}</span>
          client.details = (
            <Link to={'/client-fr/' + client.id} key={client.id}>
              VOIR LES DÉTAILS
            </Link>
          )
          client.update = (
            <Link to={'/client-update-fr/' + client.id} key={client.id}>
              Update Client
            </Link>
          )
          client.adminEdit =
            profile.userRole === 'Admin' ? client.update : client.details
          client.vinNum = client.testDriveVIN ? client.testDriveVIN : '--'
          client.testDriveDate = client.dateStart ? client.dateStart : '--'
          client.testDriveLength = client.maxLddMonth
            ? client.maxLddMonth
            : '--'
          client.isValid = '--'
          if (client.validated === 'true') {
            client.isValid = <span className="fa fa-check text-success"></span>
          } else if (client.validated === 'false') {
            client.isValid = (
              <span className="fa fa-window-close text-danger"></span>
            )
          }
          if (client.timeStart && client.client && !client.client.timeStart) {
            client.client.timeStart = client.timeStart
          }
          if (client.timeEnd && client.client && !client.client.timeEnd) {
            client.client.timeEnd = client.timeEnd
          }
          client.timeSlot =
            client.client && client.client.timeStart && client.client.timeEnd
              ? client.client.timeStart + ' - ' + client.client.timeEnd
              : ''
          client.commentsParsed = client.comments
            ? `${client.comments
                .replaceAll(',', ';')
                .replaceAll('"', '')
                .replaceAll('\\', '-')
                .replaceAll('/', '-')}`
            : ''
          client.hasPurchased =
            client.hasPurchased &&
            (client.hasPurchased === 'on' ||
              client.hasPurchased === 'true' ||
              client.hasPurchased === true)
          client.hasPurchasedParsed = client.hasPurchased ? (
            <span className="fa fa-check text-success"></span>
          ) : (
            <span>--</span>
          )
          client.supriseGiftParsed = client.supriseGift
            ? `${client.supriseGift
                .replaceAll(',', ';')
                .replaceAll('"', '')
                .replaceAll('\\', '-')
                .replaceAll('/', '-')}`
            : ''
          totalReg.push(client)
          // numberOfObjects = index + 1;
          if (client.validated && client.validated === 'true') {
            // Assign each client a maxLddMonth
            contact = client.maxLddMonth === '24h' ? 150 : 250
            allContact.push(contact)
            validatedDealers.push(client)
          }
          if (client.submission) {
            try {
              let submissionYear = new Date(client.submission).getFullYear()
              if (clientMap.get(submissionYear)) {
                clientMap.get(submissionYear).push(client)
              } else {
                let clientsArray = []
                clientsArray.push(client)
                clientMap.set(submissionYear, clientsArray)
              }
            } catch (error) {
              //ignore
              console.log(error)
            }
          }
        }
        return client
      })

      let yearsList = Array.from(clientMap.keys())
      yearsList = yearsList.sort()
      yearsList.forEach(ele => {
        let opt = clientOptionArray.find(ele2 => ele2.value === ele.toString())
        if (!opt) {
          clientOptionArray.push({
            value: ele.toString(),
            label: ele.toString(),
          })
        }
      })

      !validatedDealers || validatedDealers.length < 1
        ? (numberOfvalidatedDealers = 0)
        : (numberOfvalidatedDealers = validatedDealers.length)

      let totalRegLen = totalReg.length
      const remainingDenom = profile.dealerType === 'Contact' ? 240 : 120
      // Total Dealer Credits calculations
      let totalCreds = allContact.reduce((partial_sum, a) => partial_sum + a)
      // console.log("--------->>>>>>>", totalCreds);
      // console.log("object num", numberOfObjects);
      if (
        this.state.selectedYear.value !== 'All' &&
        clientMap.size > 0 &&
        clientMap.get(Number(this.state.selectedYear.value))
      ) {
        let dealerCredits = 0
        let clientsInYear = clientMap.get(Number(this.state.selectedYear.value))
        totalRegLen = clientsInYear.length
        let validClientsInYear = []
        clientsInYear.forEach(ele => {
          if (ele.validated === 'true') {
            if (ele.maxLddMonth && ele.maxLddMonth === '24h') {
              dealerCredits += 150
            } else if (ele.maxLddMonth && ele.maxLddMonth === '48h') {
              dealerCredits += 250
            }
            validClientsInYear.push(ele)
          }
        })
        totalCreds = dealerCredits
        numberOfvalidatedDealers = validClientsInYear.length
      }

      rt = remainingDenom - totalRegLen

      const { auth } = this.props
      if (!auth.uid) return <Navigate to="/fr" />
      const csvHeaders = [
        { label: 'Customer Number', key: 'id' },
        { label: 'Dealer Code', key: 'dealerCode' },
        { label: 'Dealer Name', key: 'dealerName' },
        { label: 'Region', key: 'region' },
        { label: 'First Name', key: 'clientFirstName' },
        { label: 'Last Name', key: 'clientLastName' },
        { label: 'Full Name', key: 'fullName' },
        { label: 'Postal Code', key: 'clientPostalCode' },
        { label: 'Follow-up Date', key: 'followUp' },
        { label: 'Dealer Credit', key: 'dealerCredit' },
        { label: 'Dealer Type', key: 'dealerType' },
        // { label: 'Reason for visit', key: 'broughtDealership' },
        { label: 'Time slot', key: 'timeSlot' },
        { label: 'Client Address', key: 'clientAddress' },
        { label: 'Client Cell Phone', key: 'clientCellPhone' },
        { label: 'Client City', key: 'clientCity' },
        { label: 'Client Email', key: 'clientEmail' },
        { label: 'Client Province', key: 'clientProvince' },
        { label: 'Comments', key: 'commentsParsed' },
        { label: 'Communication Method', key: 'communicationMethod' },
        { label: 'Date End', key: 'dateEnd' },
        { label: 'Date Start', key: 'dateStart' },
        { label: 'Drop Off Alt Address', key: 'dropoffAltAddress' },
        { label: 'Drop Off Location', key: 'dropoffLocation' },
        { label: 'Follow Up', key: 'followUp' },
        { label: 'Purchased Vehicle', key: 'hasPurchased' },
        { label: 'Order ID', key: 'orderID' },
        { label: 'Pending Validation', key: 'pendingValidation' },
        { label: 'Purchase Timeframe', key: 'purchaseTimeframe' },
        { label: 'Red List', key: 'redList' },
        { label: 'Submission', key: 'submission' },
        { label: 'Suprise Gift', key: 'supriseGiftParsed' },
        { label: 'Test Drive Date', key: 'testDriveDate' },
        { label: 'Test Drive Date1', key: 'testDriveDate1' },
        { label: 'Test Drive Date2', key: 'testDriveDate2' },
        { label: 'Test Drive Length', key: 'testDriveLength' },
        { label: 'Test Drive VIN', key: 'testDriveVIN' },
        { label: 'Competitive Vehicle Brand', key: 'currentVehicle' },
        { label: 'Competitive Vehicle VIN', key: 'currentCompetitiveVehicleVIN' },
        { label: 'Unique ID', key: 'uid' },
        { label: 'Validated', key: 'validated' },
        { label: 'Vehicle Of Interest', key: 'vehicleInterest' },
        { label: 'Purchased a Lincoln after LDD', key: 'madePurchaseAfterLDD' },
        { label: 'LDD through a Referral Certificate', key: 'isReferralCert' },
        { label: 'Referral Certificate #', key: 'referralCertNum' },
        { label: 'Updated On', key: 'updatedOn' },
      ]

      const updateTotals = option => {
        let currentData =
          option.value === 'All'
            ? totalReg
            : clientMap.get(Number(option.value)) || totalReg
        this.setState({
          selectedYear: option,
          sortedTableData: currentData,
        })
      }
      const columnDefaults = {
        ...ReactTableDefaults.column,
        headerClassName: 'wordwrap',
      }

      return (
        <React.Fragment>
          <HeaderFr />
          <div className="container pt-60 pb-60">
            <div className="row">
              <div className="col-sm-12 registration-section-title pb-20">
                <h4 className="section-title">RAPPORT DU CONCESSIONNAIRE</h4>
              </div>
              <div className="col-sm-12 pb-20">
                <h4>
                  BIENVENUE AU TABLEAU DE BORD EXPÉRIENCE DÉCOUVERTE LINCOLN DE
                  VOTRE CONCESSIONNAIRE!
                </h4>
                <p>
                  Vous trouverez ci-dessous le bilan de vos Expériences
                  découvertes Lincoln, y compris les crédits du relevé des
                  pièces
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 registration-section-title pb-20">
                <Select
                  id="submissionYear"
                  options={clientOptionArray}
                  onChange={value => updateTotals(value)}
                  value={this.state.selectedYear}
                />
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-sm-12">
                <div className="row gutters">
                  <div className="col-md-3 col-sm-6">
                    <div className=" number-feature">
                      <p className="title">
                        Nombre total{' '}
                        {this.state.selectedYear.value !== 'All'
                          ? this.state.selectedYear.value
                          : ''}
                        <br />
                        d'essais enregistrés
                      </p>
                      <p className="count">{totalRegLen}</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className=" number-feature">
                      <p className="title">
                        Nombre <br />
                        Apprové{' '}
                        {this.state.selectedYear.value !== 'All'
                          ? this.state.selectedYear.value
                          : ''}
                      </p>
                      <p className="count">{numberOfvalidatedDealers}</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className=" number-feature">
                      <p className="title">
                        ESSAIS CONDUIT QUI{' '}
                        {this.state.selectedYear.value !== 'All'
                          ? this.state.selectedYear.value
                          : ''}
                        <br />
                        RESTE POUR L'ANNÉE CIVILE
                      </p>
                      <p className="count">
                        {this.state.selectedYear.value !== 'All'
                          ? `${rt + '/' + remainingDenom}`
                          : '--'}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className=" number-feature">
                      <p className="title">
                        TOTAL DES CRÉDITS{' '}
                        {this.state.selectedYear.value !== 'All'
                          ? this.state.selectedYear.value
                          : ''}
                        <br />
                        DU CONCESSIONNAIRE
                      </p>
                      <p className="count">${totalCreds}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DownloadDiv>
              {profile.userRole === 'Admin' ? (
                <CSVLink
                  headers={csvHeaders}
                  filename={
                    'dealer-report-' +
                    this.state.csvMonth +
                    '-' +
                    this.state.csvYear +
                    '.csv'
                  }
                  className="btn btn-primary"
                  data={this.state.sortedTableData}
                  target="_blank"
                >
                  TÉLÉCHARGEZ LE RAPPORT
                </CSVLink>
              ) : null}
            </DownloadDiv>
            <TableDiv>
              <ReactTable
                previousText={'Précédent'}
                nextText={'Prochain'}
                ofText={'sur'}
                rowsText={'Lignes'}
                column={columnDefaults}
                defaultPageSize={10}
                className="-striped -highlight"
                filterable
                showPageSizeOptions={true}
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                data={
                  this.state.selectedYear.value === 'All'
                    ? totalReg
                    : clientMap.get(Number(this.state.selectedYear.value)) ||
                      totalReg
                }
                onFilteredChange={() => {
                  let data =
                    this.sortedTableDataRef.getResolvedState().sortedData
                  let sortedData
                  if (data) {
                    sortedData = data.map(ele => ele._original)
                  }
                  this.setState({
                    sortedTableData: sortedData || totalReg,
                  })
                }}
                ref={r => {
                  this.sortedTableDataRef = r
                }}
                onFetchData={(state, instance) => {
                  let data = state.sortedData
                  let loadedData
                  if (data) {
                    loadedData = data.map(ele => ele._original)
                  }
                  this.setState({
                    sortedTableData: loadedData || totalReg,
                  })
                }}
                columns={[
                  {
                    columns: [
                      {
                        Header: 'DATE DE SOUMISSION',
                        accessor: 'submission',
                        Cell: row => (
                          <div style={{ textAlign: 'center' }}>{row.value}</div>
                        ),
                        filterMethod: (filter, row) => {
                          return (
                            String(row[filter.id]).startsWith(filter.value) ||
                            String(row[filter.id]).endsWith(filter.value)
                          )
                        },
                      },
                      {
                        Header: 'Indicatif Concessionaire',
                        accessor: 'dealerCode',
                        sortable: false,
                        filterable: false,
                        Cell: row => (
                          <div style={{ textAlign: 'center' }}>{row.value}</div>
                        ),
                        filterMethod: (filter, row) => {
                          return (
                            String(row[filter.id]).startsWith(filter.value) ||
                            String(row[filter.id]).endsWith(filter.value)
                          )
                        },
                      },
                      {
                        Header: 'NUMÉRO DE COMMANDE',
                        accessor: 'validationLink',
                        sortable: false,
                        Cell: row => (
                          <div style={{ textAlign: 'center' }}>{row.value}</div>
                        ),
                        filterMethod: (filter, row) => {
                          let id = row._original ? row._original.id : ''
                          return (
                            id.startsWith(filter.value) ||
                            id.endsWith(filter.value)
                          )
                        },
                      },
                      {
                        Header: 'NOM DU CLIENT',
                        accessor: 'fullName',
                        Cell: row => (
                          <div style={{ textAlign: 'center' }}>{row.value}</div>
                        ),
                        filterMethod: (filter, row) => {
                          return (
                            row[filter.id] &&
                            filter.id &&
                            row[filter.id]
                              .toLowerCase()
                              .indexOf(filter.value.toLowerCase()) !== -1
                          )
                        },
                      },
                      {
                        Header: 'APPROBATIONS',
                        accessor: 'isValid',
                        Cell: row => (
                          <div style={{ textAlign: 'center' }}>{row.value}</div>
                        ),
                        sortable: false,
                        filterMethod: (filter, row) => {
                          if (filter.value === 'all') {
                            return true
                          }
                          if (filter.value === '--') {
                            return (
                              !row._original.validated ||
                              row._original.validated === 'false'
                            )
                          }
                          return (
                            row._original &&
                            row._original.validated === filter.value
                          )
                        },
                        Filter: ({ filter, onChange }) => (
                          <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter ? filter.value : 'all'}
                          >
                            <option value="all">Afficher tout</option>
                            <option value="--">--</option>
                            <option value="true">Approuvé</option>
                            <option value="false">Non Approuvé</option>
                          </select>
                        ),
                      },
                      {
                        Header: 'DATE DE L’ESSAI ROUTIER',
                        accessor: 'testDriveDate',
                        Cell: row => (
                          <div style={{ textAlign: 'center' }}>{row.value}</div>
                        ),
                        filterMethod: (filter, row) => {
                          return (
                            String(row[filter.id]).startsWith(filter.value) ||
                            String(row[filter.id]).endsWith(filter.value)
                          )
                        },
                      },
                      {
                        Header: "DURÉE D'ESSAI ROUTIER",
                        accessor: 'testDriveLength',
                        Cell: row => (
                          <div style={{ textAlign: 'center' }}>{row.value}</div>
                        ),
                        filterMethod: (filter, row) => {
                          return (
                            String(row[filter.id]).startsWith(filter.value) ||
                            String(row[filter.id]).endsWith(filter.value)
                          )
                        },
                      },
                      {
                        Header: 'Véhicule acheté',
                        accessor: 'hasPurchasedParsed',
                        sortable: false,
                        filterable: true,
                        Cell: row => (
                          <div style={{ textAlign: 'center' }}>{row.value}</div>
                        ),
                        filterMethod: (filter, row) => {
                          if (filter.value === 'all') {
                            return true
                          }
                          return (
                            row._original &&
                            String(row._original.hasPurchased) === filter.value
                          )
                        },
                        Filter: ({ filter, onChange }) => (
                          <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter ? filter.value : 'all'}
                          >
                            <option value="all">Afficher tout</option>
                            <option value={'true'}>Acheté</option>
                            <option value={'false'}>Pas Acheté</option>
                          </select>
                        ),
                      },
                      {
                        Header: 'ACTIONS',
                        accessor: 'adminEdit',
                        sortable: false,
                        filterable: false,
                        Cell: row => (
                          <div style={{ textAlign: 'center' }}>{row.value}</div>
                        ),
                        filterMethod: (filter, row) => {
                          return (
                            String(row[filter.id]).startsWith(filter.value) ||
                            String(row[filter.id]).endsWith(filter.value)
                          )
                        },
                      },
                    ],
                  },
                ]}
              />
            </TableDiv>
            {/* <div className="row mt-60">
              <div className="col-sm-12 text-right">
                <Link to="#" className="btn btn-primary btn-small">Allocation de concessionnaire</Link>
              </div>
            </div> */}
            <div className="row mt-10">
              <div className="col-sm-12">
                {/* { <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">DATE DE SOUMISSION</th>
                      <th className="text-center">NUMÉRO DE COMMANDE</th>
                      <th className="text-center">NOM DU CLIENT</th>
                      <th className="text-center">APPROBATIONS</th>
                      <th className="text-center">
                        DATE DE
                        <br />
                        L’ESSAI ROUTIER
                      </th>
                      <th className="text-center">
                        DURÉE
                        <br />
                        D'ESSAI ROUTIER
                      </th>
                      <th className="text-center">VIN DE VÉHICULE À ESSAI</th>
                      <th className="text-center">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ClientReportListFr clients={clients} profile={profile} />
                  </tbody>
                </table>} */}
                <p>
                  {/* <Link to="#!">View Vehicles Purchased Report</Link> */}
                  <br />
                </p>
                {/* <p>Les information client sur le programme Expérience découverte Lincoln seront consignés pour alimenter les ventes de véhicules. La section « Consulter le rapport des ventes de véhicules » de votre concessionnaire sera actualisée dès que les informations sur les ventes seront disponibles.</p> */}
              </div>
            </div>
          </div>
          {/* <SupportFR /> */}
          <FooterFr />
        </React.Fragment>
      )
    } else {
      return (
        <div
          style={{
            minWidth: '100% !important',
            minHeight: '100% !important',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            top: '120px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ padding: '5em' }}>
            <BallTriangle
              color="#c1bb00"
              height={80}
              width={80}
              wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
            />
            <h1 style={{ paddingTop: '1em' }}>Loading</h1>
          </div>
        </div>
      )
    }
  }
}
const mapStateToProps = state => {
  return {
    clients: state.firestore.ordered.clients,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}
export default compose(
  connect(mapStateToProps),
  firestoreConnect(store => {
    const profile = store.profile
    let dealerCode = ''
    if (store && store.firebase && profile && profile.dealerCode) {
      dealerCode = profile.dealerCode
    }
    return [{ collection: 'clients', where: ['dealerCode', '==', dealerCode] }]
  })
)(DealerReportFr)
