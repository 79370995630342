import React from 'react';

const ProgramParametersFr = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row mt-30">
          <div className="col-lg-12">
            <div className="faq-section-title text-center col-lg-12 mb-20">
              <h3 className="section-title">
                Détails du programme d’essai routier à la découverte de Lincoln 2025
              </h3>
            </div>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Période du programme</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <p className="static-dark-grey">
              Du 1<sup>er</sup> janvier au 31 décembre 2025
            </p>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Admissibilité des détaillants
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Tous les détaillants Lincoln peuvent participer au programme d’essai routier à la découverte de Lincoln.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Pour participer au programme 2025 et être admissibles au soutien de Lincoln Canada, les détaillants DOIVENT remplir le formulaire d’inscription/Surprise et Enchantement, et le soumettre au Secrétariat du programme.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Nombre d’essais routiers mensuels alloués par détaillant : 10 par mois.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">Admissibilité des véhicules</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Corsair, Nautilus, Aviator, Navigator 2024 neufs
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Corsair, Nautilus, Aviator, Navigator 2025 neufs
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">NOUVEAU POUR 2025</h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Nombre d’essais routiers mensuels alloués par détaillant : 10 par mois, quel que soit le volume des ventes Lincoln.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Les propriétaires actuels de Ford Edge sont admissibles, à condition que le NIV soit inclus dans le portail d’inscription.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Autres points saillants du programme
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Seuls les prospects conduisant des marques concurrentes sont admissibles.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Les détaillants participants doivent inclure le modèle et le NIV du véhicule actuel des participants dans l’inscription.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Les éléments du programme illustreront la vision de Lincoln d’une expérience chaleureuse, humaine et personnalisée.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Les détaillants participants peuvent accéder à la lettre de bienvenue au client et au matériel publicitaire pour leur site Web et leurs réseaux sociaux.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Chaque essai routier comptera comme un jour de prêt de véhicule afin de respecter les exigences d’utilisation de 50 %.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Le véhicule personnel du client sera lavé.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-10 mb-50">
          <div className="col-lg-12">
            <h3 className="title text-left">
              Responsabilités du détaillant participant
            </h3>
          </div>
          <div className="sub-title col-lg-12 mt-15">
            <ul>
              <li>
                <p>
                  <span>&#9679;</span> Inclure le modèle et le NIV du véhicule actuel des participants dans l’inscription.
                </p>
              </li>
              <li>
                <p>
                  <span>&#9679;</span> Déposer une lettre personnalisée et une carte de bienvenue Lincoln sur le siège conducteur du véhicule d’essai.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProgramParametersFr;
